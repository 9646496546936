import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IChangeEvent } from '@rjsf/core';
import validator from '@rjsf/validator-ajv8';
import { Form } from '@procore/soa-ui';

import { DetailPageLayout } from '../../layouts';
import {
  MutationAddTodoArgs,
  useAddTodoMutation,
} from '../../generated/graphql';

const rootSchema = require('../../../../worker/gen/json-schema/typescript-json-schema.json');
const schema = rootSchema.definitions.MutationAddTodoArgs;

const uiSchema = {
  'ui:options': {
    order: ['value', 'expiration'],
    core: {
      columns: 2,
    },
  },
  expiration: {
    'ui:widget': 'date',
  },
};

const date = new Date();
date.setDate(date.getDate() + 1);
const tomorrow = date;
const initialValues = {
  expiration: tomorrow.toISOString(),
};

export function AddTodo() {
  const navigate = useNavigate();
  const { mutateAsync } = useAddTodoMutation();

  const onSubmit = ({ formData }: IChangeEvent<MutationAddTodoArgs, any>) => {
    mutateAsync(formData).then(() => {
      navigate('/todos');
    });
  };

  return (
    <DetailPageLayout title="Add Todo">
      <Form
        onSubmit={onSubmit}
        schema={schema}
        uiSchema={uiSchema}
        formData={initialValues}
        validator={validator}
      />
    </DetailPageLayout>
  );
}
